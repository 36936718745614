import * as React from "react";
import Layout from "../../components/layout";
import { mainContent, introSection } from "../subpage.module.scss";

const SendinblueIframePage = () => {
  return (
    <Layout pageTitle="Anmeldung DMEA 2023" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
          <iframe
            width="100%"
            height={305}
            title="sibIframe"
            src="https://e309d24b.sibforms.com/serve/MUIEAAz4_lcSwunrHbBVlPT5CPAB0QSZ3s05oo2ZWQY1WKKX2lenmrYkSGQsCf5fyMxcn8GQ8G_CbAadn58SB4HO85cHNxIgMPnG6hqIWTMIAsuqMnBm-2ZPUXV6-NmXG8KetsMJM8HXYOuSlhpbDy-rSFIHimQih0eAuTJHrVquOt5JwcLl-2AX4q3HrgAm5X-tU36H9chqua4V"
            frameBorder="0"
            scrolling="auto"
            allowFullScreen
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              maxWidth: "100%",
            }}
          ></iframe>
        </section>
      </main>
    </Layout>
  );
};

export default SendinblueIframePage;
